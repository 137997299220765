import imgTV from '../../../src/images/data/MYSKY.png';
import ios from '../../../src/images/data/app/my-sky/mysky-ios.png';
import and from '../../../src/images/data/app/my-sky/mysky-android.png';
import ico from '../../../src/images/data/My_Sky_MS_logo_Retail_App_Assets_IT.png';

import logo from '../../../src/images/data/logo-colored.jpg';

const app = {
    data : {
        wp: {
            app: {
                dataHeader : {
                    home:true, 
                    back:true,
                    logo:logo,
                },
                links :
                    {
                    prev : '/apps/sky-kids', 
                    next : '/apps/sky-go'
                },
                options : [
                    {
                        id: 'q-app',
                        ico : ico,
                        text : '<h1>My Sky</h1><p>Brilliantly simple and personalised to you, My Sky makes managing your account, paying your bill and getting help easier than ever before. Get instant access to the information you need, when you need it. With My Sky, you can join our free loyalty programme, Sky VIP. It’s also the quickest and easiest way to securely manage your Sky products and services, pay your bill and get help if you need it.</p>',
                        qr : [
                            {
                                img : ios,
                                code : 'ios',
                                link : '',
                                label : 'iOS'
                            },
                            {
                                img : and,
                                code : 'and',
                                link : '',
                                label : 'Android'
                            },
                            
                        ],
                        image: {
                            mediaItemUrl:imgTV
                        },
                    },
                ]
            }
        },

    }
}

export default app;